body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes fadeIn {from {opacity: 0;}to {opacity: 1;}}
*{margin:0;padding:0;animation: fadeIn 1s ease-in-out;outline: inherit;}
dl, ol, ul{margin:0;padding:0;outline: inherit;}
ul, li{list-style-type:none;}
a{color:inherit;text-decoration:inherit;}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}


/* HEADER CSS */

#mastheadHome{position:fixed;top:0;left:0;width:100%;display: flex;align-items: center;z-index:999999;height:65px;}
.header-row{display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.logo-nav{display: flex;justify-content: space-between;align-items: center;}
.logo img{width:6rem;margin-top:2rem;}
.topnav{display:block;padding:0 0 0 6rem;transition:0.5s;}
.topnav li{display:inline-block;margin:0 2rem 0 0;}
.topnav li a{color:#000;}
/* .useoptions li.login a{color:#FFF;font-size:1rem;height:50px;line-height:50px;padding:0 1.5rem;background-color:#FFF;display: block;
    background: linear-gradient(78.59deg, #d300c5 -6.07%, #ff0069 51.38%, #ff7a00 122.18%);
}*/
/* .useoptions li.login a:hover{background-color: #0f172a !important;color: #FFF !important;transform: scale(1.05);}  */

/* .useoptions li.login a{color: #FFF;font-size: 1rem;height: 50px;line-height: 50px;padding: 0 1.5rem;display: block;background: linear-gradient(78.59deg, #d300c5 -6.07%, #ff0069 51.38%, #ff7a00 122.18%);background-size: 200% 200%;animation: gradientAnimation 3s infinite;  } */
@keyframes gradientAnimation {
  0% {background-position: 0% 50%;}
  100% {background-position: 100% 50%;}
}

.burger-menu {display: none;cursor: pointer;background: transparent;border: none;padding: 0;z-index: 1;}
.burger-menu .bar {width:24px;height:2px;background: #020617;margin:2px 0 6px 0;transition: 0.4s;}
.burger-menu.active .bar:nth-child(1) {transform: rotate(-45deg) translate(-6px, 6px);} 
.burger-menu.active .bar:nth-child(2) {opacity: 0;}
.burger-menu.active .bar:nth-child(3) {transform: rotate(45deg) translate(-6px, -6px);}

.desk-none{display:none;}

@media (max-width: 1200px) {
  .desk-none{display: block;}
  .logo-nav{justify-content: space-between;position: relative;padding:0;width: 100%;}
  .topnav{display: none;position: absolute;background-color: #FFF;height: auto;padding:2rem 1.5rem;top:77%;right:0;box-shadow: 0 0 19px rgba(0,0,0,0.1);}
  .mobile-hidden{display: block;}
  .mobile-hidden li{display:block;width:100%;margin:0}
  .mobile-hidden li a{color:#0f172a;padding:0.2rem 0;display: block;}
  .burger-menu{display: block;}
  .mobile-none{display:none;}
  .useoptions li.login a{font-size:0.8rem;padding:0.3rem 0.6rem;}
  .mobile-hidden li a.consulting-lia{border:2px solid #3b5c9b;padding:0.4rem 1rem;margin:1.5rem 0 0 0;border-radius:25px;}
}

#mastheadHome{transition: background-color 0.3s;}
/* #mastheadHome.scrolled {background-color:#FFF;} */
#mastheadHome.scrolled .logo img{width:4rem;margin-top:0rem;}

#mastheadHome.scrolled {background-color:rgba(255, 255, 255, 0.3);-webkit-backdrop-filter: blur(12px);backdrop-filter: blur(12px);border-bottom: 1px solid #eee;}
/* #mastheadHome .logo{width:100px;width:100px;margin:48px 0 5px 1.9rem;transition: 0.5s;} */
/* #mastheadHome.scrolled .logo{width:50px;width:50px;transition: 0.5s;margin:0 0 0 1.9rem;} */
/* #mastheadHome.scrolled .topnav{padding:0 0 0 2rem;transition:0.5s;} */


/* colors */

.bg-logo{background-color:hsl(219, 45%, 42%);}
.btn-logo{background-color:#3b5c9b;border-color:#3b5c9b;}
.btn-logo:hover{background-color:#1e293b;border-color:#1e293b;}
.text-logo{color:#3b5c9b}
.bg-logo-50{background-color: #f5f8ff;}

.btn-logo-outline-primary{border-color:#3b5c9b;color:#3b5c9b;}
.btn-logo-outline-primary:hover{background-color:#3b5c9b;border-color:#3b5c9b;}

/* COLOR CODES */
.text-slate-50{color:#f8fafc;}.text-slate-100{color:#f1f5f9;}.text-slate-200{color:#e2e8f0;}.text-slate-300{color:#cbd5e1;}.text-slate-400{color:#94a3b8;}.text-slate-500{color:#64748b;}.text-slate-600{color:#475569;}.text-slate-700{color:#334155;}.text-slate-800{color:#1e293b;}.text-slate-900{color:#0f172a;}.text-slate-950{color:#020617;}
.bg-slate-50{background-color:#f8fafc;}.bg-slate-100{background-color:#f1f5f9;}.bg-slate-200{background-color:#e2e8f0;}.bg-slate-300{background-color:#cbd5e1;}.bg-slate-400{background-color:#94a3b8;}.bg-slate-500{background-color:#64748b;}.bg-slate-600{background-color:#475569;}.bg-slate-700{background-color:#334155;}.bg-slate-800{background-color:#1e293b;}.bg-slate-900{background-color:#0f172a;}.bg-slate-950{background-color:#020617;}

.text-orange-50{color:#fff7ed;}.text-orange-100{color:#ffedd5;}.text-orange-200{color:#fed7aa;}.text-orange-300{color:#fdba74;}.text-orange-400{color:#fb923c;}.text-orange-500{color:#f97316;}.text-orange-600{color:#ea580c;}.text-orange-700{color:#c2410c;}.text-orange-800{color:#9a3412;}.text-orange-900{color:#7c2d12;}.text-orange-950{color:#431407;}
.bg-orange-50{background-color:#fff7ed;}.bg-orange-100{background-color:#ffedd5;}.bg-orange-200{background-color:#fed7aa;}.bg-orange-300{background-color:#fdba74;}.bg-orange-400{background-color:#fb923c;}.bg-orange-500{background-color:#f97316;}.bg-orange-600{background-color:#ea580c;}.bg-orange-700{background-color:#c2410c;}.bg-orange-800{background-color:#9a3412;}.bg-orange-900{background-color:#7c2d12;}.bg-orange-950{background-color:#431407;}


/* footer */
.footer{padding:3rem 0;border-top:1px solid #f1f5f9;}
.popularsearches{margin:25px 0 0 0;}
.footerkeywords{display:inline-block;line-height:15px;}
.footerkeywords li{display: inline-block;font-size:13px;word-spacing: normal;letter-spacing: normal;position: relative;margin:0;padding:0;}
.footerkeywords li:after{content: "|";display:inline-block;padding:0 5px;font-size: 10px;color: #94a3b8;}

/* Home page */
.hero{padding-top:5rem;}
.pashumitra-app-hero{margin:0 auto;}

.appdetails{display: flex;background-color: #f5f8ff;}
/* .left-column {width:50%;flex: 1; position: sticky;left: 0;top: 0;padding: 20px;height:100vh;} */
.left-column {width:50%;flex: 1;height:100vh;}
.left-column-inner {width:100%;height:100%;}

.app-scroll{position: relative;width:100%;height:100%;display: flex;justify-content: center;align-items: center;}
.app-scroll .mobile-app{width:78%;padding-top:3rem;}
.app-scroll .mobile-screens{width:100%;top:50%;left:50%;position: absolute;margin-top:-50%;margin-left:-37%;}
.app-scroll .mobile-screens img{max-width:80%;}

.right-column {width:50%;flex: 1;position: relative;}

.appdetails-list{padding:1.5rem 0 5rem 0;}
.appdetails-list li{position: relative;padding:0 0 1rem 2.5rem;}
.appdetails-list li .bi{position: absolute;left:0;}

.advertisement{margin:5rem 0;}
.advertisement-appimg{transform: rotate(-12deg);}
.adver-app{position: relative;}
/* .adver-app:before{height:300px;width:300px;content:"";position:absolute;top:0;left:0;} */
.adver-stru{transform: rotate(12deg);}


/* About Page */
.aboutpage{padding:6rem 0 0 0;}
.chairman-talks{margin-top:3rem;margin-bottom:3rem;}
.founderimg{height:250px;width:250px;border-radius:50%;overflow:hidden;margin:2rem auto 2rem auto }

/* csractivities */
.csractivitiespage{padding:6rem 0 0 0;}
.csractivities{padding:0 0 6rem 0;}

.csractivitydetailpage{padding:6rem 0 0 0;}

/* contactPage */
.contactPage{padding:6rem 0;}
.contact-info1{padding:1rem;}
.contact-info2{padding:1rem;margin:1.8rem 0 0 0;}
.contact-frm-wrap{padding:3rem;}

/* Ddownload App */
.downloadapp{padding:10rem 0;background-color:#f5f8ff;}
.appdemo{width:320px;margin:0 auto;padding:0;}/*transform: rotate(12deg);*/
.appdemo:before{height:100%;width:100%;background-color:#000;position: absolute;}
.scanqr{margin-top:1.5rem;}
.google-apple{margin-top:150px;}

.mobile-view-stick{display: none;}
@media (min-width: 0px) and (max-width: 992px) { 
  .hero{padding:5rem 0;}
  .pashumitra-app-hero{display:none;}
  .appdetails{padding:5rem 0;}
  .left-column{display:none;}
  .right-column .vh-100{height:auto !important;}
  .mobile-view-stick{width:80%;display: block;}
  .advertisement{margin:3rem 0;}
  .advertisement-appimg{width:60%;transform: rotate(-10deg);margin:0 auto;display: block;}
  .adver-stru{width:60%;transform: rotate(10deg);margin:0 auto;display: block;}
  
  .contact-info1{padding:1rem;margin:0 0 0.8rem 0;}
  .contact-info2{margin: 0 0 1rem 0;}
  .contact-frm-wrap{padding:1rem;}

  
.footer{padding:3rem 0 0 0;}

.mobile-mb-10{margin-bottom:1rem;}
}